<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Game Modes</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark to="/gamemodes/detail" class="mb-2 ma-2"
        >Insert</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="result.data"
      :options.sync="options"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.m="{ item }">
        <div>
          {{
            item.m == 1
              ? "WheelFortune"
              : item.m == 2
              ? "Treasure"
              : item.m == 3
              ? "Daruma"
              : item.m == 4
              ? "CoinFlip"
              : item.m == 5
              ? "SuperVegasWheel"
              : item.m == 6
              ? "MultiplierWheel"
              : item.m == 7
              ? "MultiplierCard"
              : item.m == 8
              ? "IceDaruma"
              : item.m == 9
              ? "LuckyPickClaim"
              : item.m == 10
              ? "LuckyPick"
              : item.m == 11
              ? "WinxClub"
              : item.m == 12
              ? "RoyalWheel"
              : "Check Mini Game Type"
          }}
        </div>
      </template>
      <template v-slot:item.pt="{ item }">
        <div>
          {{
            item.pt == -1
              ? "Maintenance"
              : item.pt == 0
              ? "Normal"
              : item.pt == 1
              ? "New"
              : item.pt == 2
              ? "Hot"
              : "Check Pirority Type"
          }}
        </div>
      </template>
      <template v-slot:item.sa="{ item }">
        <div>
          {{ item.sa }}
        </div></template
      >
      <template v-slot:item.fs="{ item }">
        <div>
          {{
            item.fs == 0
              ? "None"
              : item.fs == 1
              ? "FreeSpins"
              : item.fs == 2
              ? "Wild"
              : item.fs == 4
              ? "Medusa"
              : item.fs == 8
              ? "Lion"
              : item.fs == 16
              ? "Bull"
              : item.fs == 32
              ? "Eagle"
              : item.fs == 64
              ? "Delta"
              : item.fs == 128
              ? "K"
              : item.fs == 256
              ? "Q"
              : item.fs == 512
              ? "J"
              : item.fs == 1024
              ? "Candy"
              : item.fs == 2048
              ? "Ten"
              : item.fs == 4096
              ? "Nine"
              : item.fs == 8192
              ? "Minor"
              : item.fs == 16384
              ? "Major"
              : item.fs == 32768
              ? "Grand"
              : item.fs == 65536
              ? "Bet"
              : item.fs == 131072
              ? "Scatter"
              : item.fs == 262144
              ? "Jackpot"
              : item.fs == 524288
              ? "Multiplier"
              : "Check Symbol"
          }}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <!-- <v-icon small @click="deletePopup(item)">delete</v-icon> -->
      </template>
      <template v-slot:item.ss="{ item }">
        <v-icon small @click="slotSettings(item)">mdiJson</v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        :total-visible="20"
        @input="initialize"
        :length="result.totalPage"
      ></v-pagination>
    </div>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5 red lighten-2"> Warning </v-card-title>
        <v-card-text class="text-h5">
          Are you sure you want to delete?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItem">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSlotSettings" max-width="1600px">
      <v-card>
        <v-card-title>
          <span class="headline">Slot Settings</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogSlotSettings = false"
            >Close</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <json-viewer
            name="df"
            :value="itemSlotSettings"
            :expand-depth="5"
            theme="my-awesome-json-theme"
            sort
          ></json-viewer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialogEdit" v-model="dialogEdit" max-width="1600px">
      <v-card>
        <v-card-title>
          <span class="headline">Game Mode</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1"  @click="dialogEdit = false"
            >Close</v-btn
          >
          <v-btn color="blue darken-1"  @click="save">Save</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="1" md="1">
                <v-text-field
                  v-model="detail.i"
                  label="Id"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field v-model="detail.n" label="Name"></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                <v-text-field
                  v-model="detail.b"
                  label="Bundle Id"
                  disabled
                ></v-text-field>
              </v-col> -->
              <!-- <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model="detail.g"
                  label="Group Id"
                  disabled
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="1" md="1">
                <v-text-field v-model="detail.o" label="Order"></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <!-- <v-text-field
                  v-model="detail.ie"
                  label="Is Enable"
                ></v-text-field> -->
                <v-switch
                  v-model="detail.ie"
                  :label="`Is Enable`"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <v-text-field
                  v-model="detail.ml"
                  label="Min Level"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model="detail.m"
                  label="Min Game Type"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model="detail.fs"
                  label="Free Spin Symbol"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model="detail.mf"
                  label="Min Free Spin Count"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-model="detail.mg"
                  label="Min Grand Jackpot Bet"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-autocomplete
                  v-model="detail.pt"
                  label="Priority Type"
                  :counter="max"
                  :items="priorityType"
                  item-value="key"
                  item-text="value"
                  placeholder="Select..."
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
             <v-row>
              <v-col cols="12" sm="3" md="2">
                <v-text-field
                  v-model="soundItem.key"
                  label="Sound Key"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <v-text-field
                  v-model="soundItem.n"
                  label="Sound Path"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <v-text-field
                  v-model="soundItem.v"
                  label="Volume"
                  required
                  type="number"

                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-btn color="green darken-1" @click="addSoundItem(soundItem)"
                  >Add</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-for="(item, i) in Object.entries(this.detail.sa)" :key="i">
              <v-col cols="12" sm="3" md="2">
                <v-text-field
                  v-model="item[0]"
                  label="Sound Key"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <v-text-field
                  v-model="detail.sa[item[0]].n"
                  label="Sound Path"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <v-text-field
                  v-model="detail.sa[item[0]].v"
                  label="Volume"
                  required
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-btn color="red darken-1" @click="deleteSoundItem(item)"
                  >Delete</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
          <json-viewer
            name="df"
            :value="detail.ss"
            :expand-depth="5"
            theme="my-awesome-json-theme"
            sort
          ></json-viewer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import gameType from "@/lib/gameType";
import priorityType from "@/lib/priorityType";
import router from "@/router";
export default {
  data() {
    return {
      dialog: false,
      dialogSlotSettings: false,
      dialogEdit: false,
      currentItem: {},
      detail: {},
      soundItem:{},
      gameType: gameType,
      priorityType: priorityType,
      all: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "i",
        },
        { text: "Name", value: "n" },
        { text: "Mode", value: "gm" },
        { text: "BundleId", value: "b" },
        { text: "GroupId", value: "g" },
        { text: "Order", value: "o" },
        { text: "Is Enable", value: "ie" },
        { text: "Min Level", value: "ml" },
        { text: "Mini Game Type", value: "m" },
        { text: "Free Spin Symbol", value: "fs" },
        { text: "Min Free Spin Count", value: "mf" },
        { text: "Min Grand Jackpot Bet", value: "mg" },
        { text: "Priority Type", value: "pt" },
        { text: "Sound", value: "sa" },
        { text: "Slot Settings", value: "ss" },
        { text: "Actions", value: "action", sortable: false },
      ],
      page: 1,
      total: 0,
      options: {},
      pageCount: 0,
      currentPage: 1,
      itemSlotSettings: {},
      itemsPerPage: 500,
      singleSelect: false,
      selected: [],
      result: [],
      pagination: {
        sortBy: "id",
      },
    };
  },
  methods: {
    async save() {
      await this.$store.dispatch("gameModes/post", this.detail);
      (this.dialogEdit = false), router.push("/gameModes");
    },
    async deletePopup(item) {
      this.currentItem = item;
      this.dialog = true;
    },
    async slotSettings(item) {
      this.itemSlotSettings = item.ss;
      this.dialogSlotSettings = true;
    },
    deleteSoundItem(item) {
      // delete this.detail.sa[item[0]];

      this.$delete(this.detail.sa, item[0])

      //  // eslint-disable-next-line no-debugger
      // debugger;
    },
    addSoundItem(item) {
      this.detail.sa[item.key] = { n: item.n, v: parseFloat(item.v) };
      this.soundItem={};
    },
    async deleteItem() {
      this.dialog = false;
      await this.$store.dispatch("gameModes/deleteById", this.currentItem.i);
      await this.initialize();
    },
    async editItem(item) {
      await this.$store.dispatch("gameModes/get", item.i);
      this.detail = this.$store.state.gameModes.detail;
      this.dialogEdit = true;

    
    },
    async initialize() {
      this.result = await this.$store.dispatch("gameModes/getAll", {
        page: this.currentPage - 1,
        options: this.options,
      });
      this.all = this.$store.state.gameModes.all;
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>
